import React, { forwardRef, useState } from "react";
import AutoSuggest from "react-autosuggest";
import {
  Configure,
  Highlight,
  InstantSearch,
  useInstantSearch,
} from "react-instantsearch";

import { Icon } from "@origin-dot/components";
import { useAlgolia } from "../hooks/useAlgolia";
import { useAutocomplete } from "../hooks/useAutocomplete";

const AirportAutoComplete = ({
  className,
  placeholder,
  ResultComponent,
  ContainerComponent,
  onSelect = () => {},
  onCancel = () => {},
}) => {
  const { refine, currentRefinement } = useAutocomplete();
  const { results: searchResults } = useInstantSearch();
  const [value, setValue] = useState(currentRefinement);

  return (
    <AutoSuggest
      suggestions={searchResults?.hits || []}
      alwaysRenderSuggestions
      highlightFirstSuggestion
      onSuggestionsFetchRequested={({ value: v }) => refine(v)}
      onSuggestionsClearRequested={() => {
        setValue("");
        refine();
      }}
      getSuggestionValue={() => ""}
      renderSuggestion={(suggestion, { isHighlighted }) => (
        <ResultComponent data={suggestion} isHighlighted={isHighlighted} />
      )}
      renderSuggestionsContainer={({ containerProps, children, query }) => (
        <ContainerComponent {...containerProps} isEmpty={!children && query}>
          {children}
        </ContainerComponent>
      )}
      onSuggestionSelected={(_event, { suggestion }) => {
        onSelect(suggestion);
        setValue("");
      }}
      renderSectionTitle={() => {}}
      getSectionSuggestions={() => {}}
      inputProps={{
        value,
        spellCheck: false,
        onChange: (event, { method }) => {
          if (method === "escape") onCancel();
          setValue(event.target.value || "");
        },
        className,
        placeholder,
        autoFocus: true,
      }}
      theme={{
        container: { position: "relative" },
        suggestionsContainer: { display: "none" },
        suggestionsContainerOpen: { display: "block", position: "absolute" },
      }}
    />
  );
};

const AirportSearchContainer = forwardRef(function AirportSearchContainer(
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className="w-full shadow bg-cardBackgroundColor rounded overflow-hidden z-50 mt-1"
      {...props}
    >
      {children}
    </div>
  );
});

const AirportSearchResult = ({ data, isHighlighted }) => {
  return (
    <div
      key={data.objectID}
      className={`flex align-middle text-xs p-1 cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <Icon className="mr-1 flex-shrink-0" name="airport" size={16} />
      <div className="font-bold flex-shrink-0">
        <Highlight hit={data} attribute="code" highlightedTagName="mark" />
      </div>
      <div className="truncate">
        &nbsp;-&nbsp;
        <Highlight hit={data} attribute="name" highlightedTagName="mark" />
        {", "}
        <Highlight hit={data} attribute="city" highlightedTagName="mark" />
      </div>
    </div>
  );
};

export const AirportLookup = ({ className, onSelect }) => {
  const algolia = useAlgolia();
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={className}>
      {isOpen ? (
        <InstantSearch
          searchClient={algolia}
          indexName="shared_admin_airports"
          future={{
            preserveSharedStateOnUnmount: true,
          }}
        >
          <Configure hitsPerPage={10} />
          <AirportAutoComplete
            className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs border border-searchBorderColor rounded px-2 py-1 appearance-none"
            placeholder="Airport lookup"
            ResultComponent={AirportSearchResult}
            ContainerComponent={AirportSearchContainer}
            onSelect={(suggestion) => {
              setOpen(false);
              onSelect({
                code: suggestion.objectID,
                city: suggestion.city,
                timeZone: suggestion.timeZone,
                location: {
                  latitude: suggestion.latitude,
                  longitude: suggestion.longitude,
                },
              });
            }}
            onCancel={() => setOpen(false)}
            openOnFocus={false}
          />
        </InstantSearch>
      ) : (
        <button
          type="button"
          className="w-full text-xs font-bold flex hover:bg-separatorColor justify-center items-center rounded px-2 py-1 border border-searchBorderColor"
          onClick={() => setOpen(true)}
        >
          Airport lookup
        </button>
      )}
    </div>
  );
};
