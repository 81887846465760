import moment from "moment";
import React, { forwardRef, useMemo } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { Link, useNavigate } from "react-router-dom";

import { faEnvelope as emailIcon } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faEye as viewIcon } from "@fortawesome/free-solid-svg-icons/faEye";
import { faPhoneAlt as phoneIcon } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faPlus as addIcon } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser as userIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { Avatar } from "@origin-dot/components";
import {
  collection,
  deleteField,
  doc,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Configure, InstantSearch } from "react-instantsearch";
import { firestore, functions } from "../../firebase";
import { getDisplayName } from "../../helpers/user";

import { ActionButton } from "../ActionButton";
import { CallLink } from "../CallLink";
import { CopyButton } from "../CopyButton";
import { SubstituteUserToggle } from "./SubstituteUserToggle";
import { UserTravelDocuments } from "./UserTravelDocuments";

import { useAlgolia } from "../../hooks/useAlgolia";
import pipedriveIcon from "../../images/pipedrive.png";
import zohoIcon from "../../images/zoho.png";
import { AutoComplete } from "../AutoComplete";
import { OnboardingQuestionnaire } from "../Questionnaires";
import { TripSearchResult } from "../trip/TripSearchResult";
import { prettyStatus } from "../tripRequest/prettyStatus";
import { AutoTriageToggle } from "./AutoTriageToggle";
import { SubscribeToggle } from "./SubscribeToggle";
import { UserSearchResult } from "./UserSearchResult";
import { WeekendBotToggle } from "./WeekendBotToggle";

const TripRequestItem = ({ tripRequestSnapshot }) => {
  const tripRequestId = tripRequestSnapshot.id;
  const tripRequest = tripRequestSnapshot.data();
  const { statusTransitionTimes } = tripRequest;

  // TODO: investigate why status transition times are occasionally missing
  const transitionTime =
    (statusTransitionTimes?.[tripRequest.status] &&
      statusTransitionTimes[tripRequest.status].toDate()) ||
    null;

  return (
    <Link className="text-sm mb-2" to={`/tripRequests/${tripRequestId}?draft`}>
      <div className="px-2 py-1 border border-dividerColor rounded">
        <div className="italic">{tripRequest.title}</div>
        <div className="flex justify-between items-center">
          <div className="font-bold">
            {tripRequest.isArchived
              ? "Archived"
              : prettyStatus(tripRequest.status)}
          </div>
          {transitionTime ? (
            <div className="text-xs"> {moment(transitionTime).fromNow()} </div>
          ) : (
            <div className="text-xs text-mainTintColor ml-1">
              Error: Trip Status Transition Date Missing. Please send this page
              URL and error message to @eng on Slack.
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

const ReferralItem = ({ referralSnapshot }) => {
  const referralData = referralSnapshot.data();
  const referredUserReference = useMemo(
    () => doc(firestore, "users", referralData.userId),
    [referralData.userId],
  );
  const referredByUserReference = useMemo(
    () => doc(firestore, "users", referralData.referredBy),
    [referralData.referredBy],
  );

  const [referredUser, referredLoading, referredError] = useDocumentData(
    referredUserReference,
  );
  const [referredByUser, referredByLoading, referredByError] = useDocumentData(
    referredByUserReference,
  );

  if (
    referredLoading ||
    referredError ||
    !referredUser ||
    referredByLoading ||
    referredByError ||
    !referredByUser
  )
    return null;

  return (
    <div className="mb-2">
      <div className="border border-dividerColor rounded">
        <Link className="text-sm mb-2" to={`/users/${referralData.userId}`}>
          <div className="text-sm flex items-center">
            <div className="px-2 py-2">
              <Avatar
                cachedPicture={referredUser.picture}
                userId={referredUserReference.id}
                size={40}
              />
            </div>
            <div className="flex-grow">
              <div className="font-bold">{getDisplayName(referredUser)}</div>
              <div>
                {referralData.hasBookedTrip
                  ? "First trip booked 🎉"
                  : "Downloaded the Scenset app"}
              </div>
            </div>
          </div>
          <div className="text-sm flex items-center">
            <div className="flex-grow mr-1">Reward Type:</div>
            <div className="font-bold text-right">
              {referralData.type || "DINNER"}
            </div>
          </div>
        </Link>
        {referralData.hasBookedTrip &&
          referralData.type !== "NO_CURATION_FEE" && (
            <ActionButton
              className="mt-1 text-textColor"
              disabled={referralData.referralGiftReceived}
              onAction={async () => {
                if (
                  // eslint-disable-next-line no-alert, no-restricted-globals
                  !confirm(
                    `Mark ${getDisplayName(
                      referredByUser,
                    )} as having received their reward for referring ${getDisplayName(referredUser)}?`,
                  )
                )
                  return;
                await referralSnapshot.ref.set(
                  { referralGiftReceived: true },
                  { merge: true },
                );
              }}
            >
              {!referralData.referralGiftReceived && (
                <FontAwesomeIcon icon={addIcon} className="mr-2" />
              )}
              {referralData.referralGiftReceived
                ? "Received referral reward"
                : "Mark as received referral reward"}
            </ActionButton>
          )}
      </div>
    </div>
  );
};

const ReferredInfo = ({ referredSnapshot, userDisplayName }) => {
  const referredData = referredSnapshot.data();
  const referredByUserReference = useMemo(
    () => doc(firestore, "users", referredData.referredBy),
    [referredData.referredBy],
  );

  const [referredByUser, referredByLoading, referredByError] = useDocumentData(
    referredByUserReference,
  );

  if (referredByLoading || referredByError || !referredByUser) return null;

  return (
    <div className="mt-3">
      <Link className="text-sm" to={`/users/${referredData.referredBy}`}>
        <div className="text-sm flex items-center">
          <div className="flex-grow mr-1">Referred By:</div>
          <div className="font-bold text-right">
            {getDisplayName(referredByUser)}
          </div>
        </div>
      </Link>
      <div className="text-sm flex items-center">
        <div className="flex-grow mr-1">Reward Type:</div>
        <div className="font-bold text-right">
          {referredData.type || "DINNER"}
        </div>
      </div>
      {referredData.hasBookedTrip &&
        referredData.hasBookedTrip !== "NO_CURATION_FEE" && (
          <ActionButton
            className="mt-1 text-textColor"
            // disabled={referralData.referralGiftReceived}
            onAction={async () => {
              if (
                // eslint-disable-next-line no-alert, no-restricted-globals
                !confirm(
                  `Mark ${userDisplayName} as having received their reward for getting referred by ${getDisplayName(
                    referredByUser,
                  )}?`,
                )
              )
                return;
              await referredSnapshot.ref.set(
                { referredGiftReceived: true },
                { merge: true },
              );
            }}
          >
            {!referredData.referredGiftReceived && (
              <FontAwesomeIcon icon={addIcon} className="mr-2" />
            )}
            {referredData.referredGiftReceived
              ? "Received referred reward"
              : "Mark as received referred reward"}
          </ActionButton>
        )}
    </div>
  );
};

const SearchContainer = forwardRef(function SearchContainer(
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={
        "mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden"
      }
      {...props}
    >
      {children}
    </div>
  );
});

const SearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResult;
      case "trip":
        return TripSearchResult;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};
const DuplicateUserSearch = ({ currentUserId }) => {
  const algolia = useAlgolia();

  const mergeAdditionalUserIntoMainUser = async (extraUserId) => {
    if (extraUserId === currentUserId) {
      // eslint-disable-next-line no-alert
      alert(
        "Selected the exact same user account, please select a different account to merge in",
      );
      return;
    }
    // eslint-disable-next-line no-alert
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you want to merge the selected user profile into the profile on the page you're currently on?\nThis cannot be undone!`,
      )
    )
      return;

    const processProfileCommand = httpsCallable(
      functions,
      "processProfileCommand",
    );
    await processProfileCommand({
      command: "MergeAndDisableUser",
      params: {
        mainUserId: currentUserId,
        extraUserId,
      },
    });
  };

  return (
    <div className="relative z-30">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure filters="indexType:user" hitsPerPage={5} />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Merge duplicate user in"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="merge_additional_user_to_main_user"
          onSelect={(suggestion) =>
            mergeAdditionalUserIntoMainUser(
              suggestion.objectID.replace("user:", ""),
            )
          }
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={userIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};
export const UserInfo = ({ userId, user }) => {
  // const creationTime = user.creationTime && user.creationTime.date ? moment(user.creationTime.date.toDate()) : null;
  const navigate = useNavigate();

  const lastEventsReadTime = user?.lastReadTime?.events?.date
    ? moment(user.lastReadTime.events.date.toDate())
    : null;

  const lastInspirationEventsReadTime = user?.lastReadTime?.inspirationEvents
    ?.date
    ? moment(user.lastReadTime.inspirationEvents.date.toDate())
    : null;

  const lastMessagesReadTime = user?.lastReadTime?.messages?.date
    ? moment(user.lastReadTime.messages.date.toDate())
    : null;

  const badgeEvents = user?.badge?.events ? user.badge.events : 0;

  const badgeInspirationEvents = user?.badge?.inspirationEvents
    ? user.badge.inspirationEvents
    : 0;

  const badgeMessages = user?.badge?.messages ? user.badge.messages : 0;

  const tripRequestsQuery = useMemo(
    () =>
      query(
        collection(firestore, "tripRequests"),
        where("users", "array-contains", userId),
        orderBy("createdAt", "desc"),
      ),
    [userId],
  );

  const validReferralsQuery = useMemo(
    () =>
      query(
        collection(firestore, "referrals"),
        where("referredBy", "==", userId),
        where("isValid", "==", true),
      ),
    [userId],
  );

  const referredQuery = useMemo(
    () =>
      query(
        collection(firestore, "referrals"),
        where("userId", "==", userId),
        where("isValid", "==", true),
      ),
    [userId],
  );

  const [tripRequestsSnapshot] = useCollection(tripRequestsQuery);
  const [referralsSnapshot] = useCollection(validReferralsQuery);
  const [referredsSnapshot] = useCollection(referredQuery);

  const referredSnapshot =
    referredsSnapshot &&
    referredsSnapshot.docs.length === 1 &&
    referredsSnapshot.docs[0];

  const allowContactByPhoneToLabel = (allowContactByPhone) => {
    switch (allowContactByPhone) {
      case true:
        return "yes";
      case false:
        return "no";
      default:
        return "not specified";
    }
  };

  const changeNotificationChannelOverride = () => {
    // eslint-disable-next-line no-alert
    const channelId = prompt(
      "Please enter a Slack channel ID to use for chat notifications. Be sure to also invite @Origin to the Slack channel.\n\nTo revert to the default channel (#notifications), leave this empty.",
      user.notificationChannelOverride || "",
    );
    if (channelId || channelId === "") {
      const userRef = doc(firestore, "users", userId);
      setDoc(
        userRef,
        { notificationChannelOverride: channelId || deleteField() },
        { merge: true },
      );
    }
  };

  const backfillClientChatMessages = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `!!THREE MAJOR WARNINGS!!\n\n1. Are you sure that you have invited @origin (the Scenset bot) to the channel?\n\n2. Are you sure that you want to import all old messages for the client?\n\n3. Please note that if you do this twice it'll import everything twice!`,
      )
    )
      return;
    try {
      const processSlackCommand = httpsCallable(
        functions,
        "processSlackCommand",
      );
      await processSlackCommand({
        command: "BackfillClientChatMessages",
        params: { userId },
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.message);
    }
  };

  const zohoHost =
    process.env.ZOHO_CONTACT_URI ||
    "https://crmplus.zoho.com/scenset/index.do/cxapp/crm/org853072057/tab/Contacts";

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="flex flex-col items-center bg-cardBackgroundColor p-3 rounded shadow">
        <h2 className="text-xl font-semibold w-full text-center truncate">
          {getDisplayName(user)}
        </h2>
        <CopyButton className="w-40 text-mainTintColor" value={userId} />
        {user.pipedrivePersonId && (
          <a
            className="text-xs flex items-center"
            href={`https://origin-me.pipedrive.com/person/${user.pipedrivePersonId}`}
            target="_blank"
            rel="noreferrer"
          >
            <img alt="pipedrive" className="w-3 h-3" src={pipedriveIcon} />
            <span className="ml-1">Open in Pipedrive</span>
          </a>
        )}
        {user.zohoContactId && (
          <a
            className="text-xs flex items-center"
            href={`${zohoHost}/${user.zohoContactId}`}
            target="_blank"
            rel="noreferrer"
          >
            <img alt="zoho" className="w-3 h-3" src={zohoIcon} />
            <span className="ml-1">Open in Zoho</span>
          </a>
        )}
        <Avatar className="mt-3" userId={userId} size={96} />
        {user.mergedToUser ? (
          <div
            className="mt-3 flex items-center content-center"
            style={{ textAlign: "center" }}
          >
            User merged into
            <div className="pl-2">
              <Link to={`/users/${user.mergedToUser}`}>
                <Avatar userId={user.mergedToUser} size={40} />
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <SubstituteUserToggle className="mt-3" userId={userId} />
            <WeekendBotToggle className="mt-3" userId={userId} />
            <AutoTriageToggle className="mt-3" userId={userId} />
            <SubscribeToggle className="mt-3" userId={userId} />
            <div className="mt-3">
              <DuplicateUserSearch currentUserId={userId} />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
        {user.memberSince && (
          <div className="text-sm mb-1 whitespace-no-wrap w-full truncate">
            <span className="ml-1">
              Member Since{" "}
              {moment(user.memberSince.toDate()).format("MMM DD YYYY")}
            </span>
          </div>
        )}
        {user.creationTime && (
          <div className="text-sm mb-1 whitespace-no-wrap w-full truncate">
            <span className="ml-1">
              User Since{" "}
              {moment(user.creationTime.date.toDate()).format("MMM DD YYYY")}
            </span>
          </div>
        )}
        {user.email && (
          <div className="text-sm underline mb-1 whitespace-no-wrap w-full truncate">
            <a href={`mailto:${user.email}`}>
              <FontAwesomeIcon icon={emailIcon} fixedWidth />
              <span className="ml-1">{user.email}</span>
            </a>
          </div>
        )}
        {user.phone && (
          <div className="mb-1">
            <CallLink
              className="text-sm text-left underline whitespace-no-wrap w-full truncate"
              phone={user.phone}
            >
              <FontAwesomeIcon icon={phoneIcon} fixedWidth />
              <span className="ml-1">{user.phone}</span>
            </CallLink>
            <div className="text-sm mt-3 flex items-center">
              <div className="flex-grow mr-1">Can contact by phone?</div>
              <div className="font-bold text-right">
                {allowContactByPhoneToLabel(user.allowContactByPhone)}
              </div>
            </div>
          </div>
        )}
        <div className="text-sm mt-2 flex items-center">
          <div className="flex-grow mr-1">App build:</div>
          <div className="font-bold text-right">
            {user.lastUsedAppVersion || "?"}
          </div>
        </div>
        <div className="text-sm mt-1 flex items-center">
          <div className="flex-grow mr-1">Push enabled:</div>
          <div className="font-bold text-right">
            {user.tokens && Object.keys(user.tokens).length > 0 ? "yes" : "no"}
          </div>
        </div>
        <div className="text-sm mt-3 flex items-center">
          <FontAwesomeIcon icon={viewIcon} fixedWidth />
          <div className="flex-grow mx-1">Home:</div>
          {badgeEvents !== 0 && (
            <div className="mr-1 bg-mainTintColor text-white px-2 rounded-full font-bold">
              {badgeEvents}
            </div>
          )}
          <div className="font-bold text-right">
            {lastEventsReadTime
              ? lastEventsReadTime.format("YYYY-MM-DD HH:mm")
              : "?"}
          </div>
        </div>
        <div className="text-sm mt-1 flex items-center">
          <FontAwesomeIcon icon={viewIcon} fixedWidth />
          <div className="flex-grow mx-1">The edit:</div>
          {badgeInspirationEvents !== 0 && (
            <div className="mr-1 bg-mainTintColor text-white px-2 rounded-full font-bold">
              {badgeInspirationEvents}
            </div>
          )}
          <div className="font-bold text-right">
            {lastInspirationEventsReadTime
              ? lastInspirationEventsReadTime.format("YYYY-MM-DD HH:mm")
              : "?"}
          </div>
        </div>
        <div className="text-sm mt-1 flex items-center">
          <FontAwesomeIcon icon={viewIcon} fixedWidth />
          <div className="flex-grow mx-1">Chat:</div>
          {badgeMessages !== 0 && (
            <div className="mr-1 bg-mainTintColor text-white px-2 rounded-full font-bold">
              {badgeMessages}
            </div>
          )}
          <div className="font-bold text-right">
            {lastMessagesReadTime
              ? lastMessagesReadTime.format("YYYY-MM-DD HH:mm")
              : "?"}
          </div>
        </div>
        <div className="text-sm mt-3 flex items-center">
          <span
            className={` ${user.notificationChannelOverride ? "font-bold" : "text-textTertiaryColor"}`}
          >
            {user.notificationChannelOverride ? (
              <a
                href={`https://app.slack.com/client/TKE84QPK8/${user.notificationChannelOverride}`}
              >
                Custom Slack channel
              </a>
            ) : (
              "Default Slack channel"
            )}
          </span>
          <span>
            &nbsp;(
            <button
              type="button"
              className="underline cursor-pointer"
              onClick={changeNotificationChannelOverride}
            >
              change
            </button>
            )
          </span>
          {user.notificationChannelOverride && (
            <span>
              &nbsp;(
              <button
                type="button"
                className="underline cursor-pointer"
                onClick={backfillClientChatMessages}
              >
                backfill
              </button>
              )
            </span>
          )}
        </div>
        {referredSnapshot && (
          <ReferredInfo
            referredSnapshot={referredSnapshot}
            userDisplayName={getDisplayName(user)}
          />
        )}
      </div>
      <OnboardingQuestionnaire userId={userId} initialCollapse={true} />
      <UserTravelDocuments userId={userId} />
      <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
        <h2 className="text-lg font-semibold mb-2">Trip Requests</h2>
        {tripRequestsSnapshot?.docs.map((tripRequestSnapshot) => (
          <TripRequestItem
            key={tripRequestSnapshot.id}
            tripRequestSnapshot={tripRequestSnapshot}
          />
        ))}
        <ActionButton
          className="mt-1"
          onAction={async () => {
            if (
              // eslint-disable-next-line no-alert, no-restricted-globals
              !confirm(
                `Create a new trip request for ${getDisplayName(
                  user,
                )}?\n\nThis will add a new trip request to the user's account.\nNo push notification will be sent.`,
              )
            ) {
              return;
            }
            const processTripRequestCommand = httpsCallable(
              functions,
              "processTripRequestCommand",
            );
            const result = await processTripRequestCommand({
              command: "CreateTripRequest",
              params: { users: [userId] },
            });
            setTimeout(() => navigate(`/tripRequests/${result.data}`), 100);
          }}
        >
          <FontAwesomeIcon icon={addIcon} className="mr-2" />
          Create trip request
        </ActionButton>
      </div>
      {referralsSnapshot && !referralsSnapshot.empty && (
        <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Referrals</h2>

          {referralsSnapshot.docs.map((referralSnapshot) => (
            <ReferralItem
              key={referralSnapshot.id}
              referralSnapshot={referralSnapshot}
            />
          ))}
        </div>
      )}
    </div>
  );
};
