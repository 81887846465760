import path from "path";
import { faFileAlt as fileIcon } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFileArchive as fileArchiveIcon } from "@fortawesome/free-solid-svg-icons/faFileArchive";
import { faFileAudio as fileAudioIcon } from "@fortawesome/free-solid-svg-icons/faFileAudio";
import { faFileImage as fileImageIcon } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { faFilePdf as filePdfIcon } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faFileVideo as fileVideoIcon } from "@fortawesome/free-solid-svg-icons/faFileVideo";
import { faThumbtack as pinIcon } from "@fortawesome/free-solid-svg-icons/faThumbtack";
import { faTimesCircle as deleteIcon } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, useProfile } from "@origin-dot/components";
import createEmojiRegex from "emoji-regex";
import { deleteDoc, updateDoc } from "firebase/firestore";
import moment from "moment/moment";
import React, { forwardRef, useEffect, useState } from "react";
import { getDisplayName } from "../../helpers/user";
import { useImageUrl } from "../../hooks/useImageUrl";
import { FormattedText } from "../FormattedText";
import { Image } from "../Image";
import { NewCardWrapper } from "../card/NewCardWrapper";

const LinkedImage = ({ image, ...props }) => {
  const url = useImageUrl(image.url || image.path);

  let aspect;

  if (image.width && image.height) aspect = image.width / image.height;

  return (
    <a href={url} target="_blank" rel="noreferrer" {...props}>
      <Image path={url} aspect={aspect} style={{ width: "100%" }} />
    </a>
  );
};

const getCleanFileName = (fileName, url) => {
  let urlName;
  try {
    urlName = url && path.basename(new URL(url).pathName);
  } catch (_err) {
    // Ignore
  }

  let name = fileName || urlName || "unnamed";
  if (!name.includes(".")) {
    const urlExtension = path.extname(urlName);
    name = `${name}${urlExtension || ".data"}`;
  }

  return name;
};

const getFileIcon = (fileName) => {
  const extension = path.extname(fileName).toLowerCase();
  switch (extension) {
    case ".7z":
    case ".gz":
    case ".rar":
    case ".tar":
    case ".zip":
      return fileArchiveIcon;
    case ".aac":
    case ".caf":
    case ".flac":
    case ".m4a":
    case ".mp3":
    case ".ogg":
    case ".wav":
    case ".wma":
      return fileAudioIcon;
    case ".gif":
    case ".jpeg":
    case ".jpg":
    case ".png":
    case ".tiff":
      return fileImageIcon;
    case ".pdf":
      return filePdfIcon;
    case ".avi":
    case ".mov":
    case ".mp4":
    case ".wmv":
      return fileVideoIcon;
    default:
      return fileIcon;
  }
};

const LinkedFile = ({ file, ...props }) => {
  const url = useImageUrl(file.url);
  const name = getCleanFileName(file.fileName, url);

  return (
    <a
      className="rounded-xl bg-cardBackgroundColor w-32 p-3 border border-searchBorderColor flex flex-col justify-center items-center"
      href={url}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <FontAwesomeIcon className="mb-2" icon={getFileIcon(name)} size="2x" />
      <div className="w-full break-all text-center text-xs text-textDimmedColor leading-none">
        {name}
      </div>
    </a>
  );
};

const UnknownMessage = () => (
  <span className="text-mainTintColor text-sm font-bold">
    UNSUPPORTED CHAT MESSAGE
  </span>
);
const TextMessage = ({
  message: { formattedText },
  ownMessage,
  showAvatar,
  isRead,
  time,
}) => (
  <div
    className={`chat-message ${
      ownMessage
        ? "chat-message-sent"
        : `chat-message-received ${showAvatar ? "avatar" : ""}`
    } inline-block font-medium px-3 py-1 relative`}
    style={{
      maxWidth: "75%",
      padding: "0.35rem 0.75rem",
      borderRadius: "0.75rem",
      overflowWrap: "break-word",
      wordBreak: "break-word",
    }}
  >
    <FormattedText source={formattedText.trim()} className="inline" />
    <span className="text-xs text-textDimmedColor float-right pt-1 pl-2">
      {isRead && "✓ "}
      {time.format("H:mm")}
    </span>
  </div>
);
const EmojiMessage = ({ message: { formattedText } }) => (
  <div className="text-5xl leading-none">{formattedText}</div>
);
const CardMessage = ({ message: { card } }) => <NewCardWrapper card={card} />;
const MediaMessage = ({ message: { media }, ownMessage }) => (
  <div
    className={`rounded-xl overflow-hidden w-2/3 ${ownMessage ? "ml-auto" : "mr-auto"}`}
  >
    {media.map((image, index) => (
      <LinkedImage key={index} image={image} style={{ width: "100%" }} />
    ))}
  </div>
);
const FilesMessage = ({ message: { files }, ownMessage }) => (
  <div
    className={`flex flex-col gap-2 ${ownMessage ? "items-end" : "items-start"}`}
  >
    {files.map((file, index) => (
      <LinkedFile key={index} file={file} />
    ))}
  </div>
);
const AudioMessage = ({
  message: {
    audio: { file, transcription },
  },
  ownMessage,
}) => (
  <div
    className={`flex flex-col items-stretch w-3/4 ${ownMessage ? "ml-auto" : "mr-auto"}`}
  >
    {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
    <audio controls preload="metadata" src={file.url} className="w-full" />
    {transcription && (
      <div className="text-xs italic mb-1 px-3">
        &ldquo;{transcription}&rdquo;
      </div>
    )}
  </div>
);

const singleEmojiRegex = new RegExp(`^(${createEmojiRegex().source}){1,3}$`);

const ChatMessage = forwardRef(function UserChatMessage(
  {
    editable,
    ownMessage,
    messageSnapshot,
    isRead,
    isEdited,
    editedMessage,
    setEditedMessage,
    showAvatar,
    showAgentName,
    userChatInputRef,
    userChatInputText,
    canBePinned = false,
    keepLeft = false,
    fullWidth = false,
  },
  ref,
) {
  const message = messageSnapshot.data();

  // Fallback to current time when still resolving server timestamp.
  const time = message.time?.date
    ? moment(message.time.date.toDate())
    : moment();
  const profile = useProfile(message.userId);
  const pinned = !!message.pinned;
  const [displayName, setDisplayName] = useState("");
  useEffect(() => {
    if (profile) {
      setDisplayName(getDisplayName(profile));
    }
  }, [profile]);

  let MessageComponent = UnknownMessage;
  if ("formattedText" in message)
    MessageComponent = message.formattedText.match(singleEmojiRegex)
      ? EmojiMessage
      : TextMessage;
  else if ("card" in message) MessageComponent = CardMessage;
  else if ("media" in message) MessageComponent = MediaMessage;
  else if ("files" in message) MessageComponent = FilesMessage;
  else if ("audio" in message) MessageComponent = AudioMessage;

  return (
    <div
      ref={ref}
      className={`${ownMessage && !keepLeft ? "text-right" : "text-left"} relative`}
    >
      {!ownMessage && showAgentName && (
        <div className="text-xs text-mainTintColor font-bold ml-10 mb-1 pl-2">
          {displayName || "\u00A0"}
        </div>
      )}
      {MessageComponent === TextMessage && (
        <div
          className={`chat-message
          ${ownMessage ? "chat-message-sent" : `chat-message-received ${showAvatar ? "avatar" : ""}`}
          ${fullWidth ? "w-full" : ""}
          ${isEdited ? "edited" : ""}
          ${editable && !isEdited ? "editable" : ""}
          inline-block font-medium px-3 py-1 ml-10 relative`}
          style={{
            maxWidth: fullWidth ? "85%" : "75%",
            padding: "0.35rem 0.75rem",
            borderRadius: "0.75rem",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
          onDoubleClick={() => {
            if (editable) {
              setEditedMessage(messageSnapshot);
              userChatInputRef.current.focus();
            }
          }}
        >
          <FormattedText
            source={isEdited ? userChatInputText : message.formattedText.trim()}
            className="inline"
            extraNewLine={false}
          />
          <span className="text-xs text-textDimmedColor float-right pt-1 pl-2">
            {isRead && "✓ "}
            {time.format("H:mm")}
          </span>
          {editable && editedMessage && isEdited && (
            <FontAwesomeIcon
              icon={deleteIcon}
              className="chat-message-action absolute right-0 top-0 text-mainTintColor bg-backgroundColor rounded-full"
              onMouseDown={() => {
                // eslint-disable-next-line no-alert, no-restricted-globals
                if (
                  !confirm("Are you sure that you want to delete this message?")
                ) {
                  return;
                }

                deleteDoc(messageSnapshot.ref);
              }}
            />
          )}
          {!isEdited && canBePinned && (
            <FontAwesomeIcon
              icon={pinIcon}
              className={`cursor-pointer chat-message-action absolute right-0 top-0 ${
                pinned ? "text-mainTintColor" : "text-textQuarternaryColor"
              } bg-backgroundColor rounded-full`}
              onMouseDown={() => {
                updateDoc(messageSnapshot.ref, { pinned: !pinned });
              }}
            />
          )}
        </div>
      )}
      {MessageComponent !== TextMessage && (
        <div className="ml-10">
          <MessageComponent
            message={message}
            ownMessage={ownMessage}
            showAvatar={showAvatar}
            isRead={isRead}
            time={time}
          />
          <div className="text-xs text-textDimmedColor px-2">
            {isRead && "✓ "}
            {time.format("H:mm")}
          </div>
        </div>
      )}
      {!ownMessage && showAvatar && (
        <Avatar
          userId={message.userId}
          className="bottom-0 absolute"
          size={28}
        />
      )}
    </div>
  );
});

export default ChatMessage;
