import algoliasearch from "algoliasearch";
import { useMemo } from "react";

import { useAuth } from "../components/auth/useAuth";

const getAlgoliaWithSearchKey = (searchKey) => {
  return algoliasearch(process.env.ALGOLIA_APP_ID, searchKey);
};

export const useAlgolia = () => {
  const auth = useAuth();
  const { searchKey } = auth.claims;
  if (!searchKey) throw new Error("User does not have a search key set");

  return useMemo(() => getAlgoliaWithSearchKey(searchKey), [searchKey]);
};
