import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { usePaginatedQuery } from "../../hooks/usePaginatedQuery";
import { useAuth } from "../auth/useAuth";
import ChatMessage from "./ChatMessage";

const ChatList = ({
  rightUserId,
  editOthers = false,
  canBePinned = false,
  messagesCollection,
  lastReadTime,
  className,
  infiniteRef,
  editedMessage,
  setEditedMessage,
  userChatInputRef,
  userChatInputText,
}) => {
  const { items, hasMore, loading, loadMore } = usePaginatedQuery(
    messagesCollection,
    {
      firstPage: 30,
      pageSize: 20,
      includeMetadataChanges: false,
    },
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState(null);

  const auth = useAuth();
  useEffect(() => {
    const { current: scrollContainer } = infiniteRef;
    function onScroll() {
      if (
        scrollContainer.scrollTop <= scrollPosition &&
        scrollContainer.scrollTop <= 0 &&
        !loading &&
        hasMore
      ) {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        setLastScrollHeight(scrollContainer.scrollHeight);
        loadMore();
      }
      setScrollPosition(scrollContainer.scrollTop);
    }

    scrollContainer.addEventListener("scroll", onScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [currentPage, hasMore, loading, loadMore, scrollPosition, infiniteRef]);

  useEffect(() => {
    const { current: scrollContainer } = infiniteRef;
    if (items.length) {
      const availableScroll =
        scrollContainer.scrollHeight - scrollContainer.scrollTop;

      if (currentPage === 1) {
        if (availableScroll >= 0) {
          scrollContainer.scrollTo({ top: availableScroll });
        }
      } else if (hasMore) {
        const keepScroll = scrollContainer.scrollHeight - lastScrollHeight;
        scrollContainer.scrollTo({ top: keepScroll });
      }
    }
  }, [currentPage, hasMore, items.length, lastScrollHeight, infiniteRef]);

  useEffect(() => {
    const { current: scrollContainer } = infiniteRef;
    if (!editedMessage) {
      scrollContainer.scrollTo({ top: scrollContainer.scrollHeight });
    }
  }, [editedMessage, infiniteRef]);

  let lastDate;
  return (
    <div
      className={`${className || ""} text-sm grid grid-col-1 content-start gap-2`}
    >
      {items?.map((messageSnapshot, index) => {
        let header;
        const time = messageSnapshot.get("time");
        // Fallback to current time when still resolving server timestamp.
        const date = (
          time?.date ? moment(time.date.toDate()) : moment()
        ).format("ll");
        if (date && date !== lastDate) {
          lastDate = date;
          header = (
            <div
              key={date}
              className="text-center text-xs font-bold text-textDimmedColor py-1"
            >
              {date}
            </div>
          );
        }
        const isRead =
          messageSnapshot.get("userId") !== rightUserId &&
          time &&
          time.date &&
          time.date.toDate() <= lastReadTime;
        const isEdited = editedMessage
          ? messageSnapshot.id === editedMessage.id
          : false;
        const showAvatar = items[index + 1]
          ? items[index].get("userId") !== items[index + 1].get("userId")
          : true;
        const showAgentName = items[index - 1]
          ? items[index].get("userId") !== items[index - 1].get("userId")
          : true;
        const ownMessage = messageSnapshot.get("userId") === rightUserId;
        const editable = editOthers
          ? messageSnapshot.get("userId") !== rightUserId
          : messageSnapshot.get("userId") === rightUserId;
        return [
          ...(header ? [header] : []),
          <ChatMessage
            key={messageSnapshot.id}
            editable={editable}
            ownMessage={ownMessage}
            canBePinned={canBePinned}
            messageSnapshot={messageSnapshot}
            isRead={isRead}
            isEdited={isEdited}
            editedMessage={editedMessage}
            setEditedMessage={setEditedMessage}
            showAvatar={showAvatar}
            showAgentName={showAgentName}
            userChatInputRef={userChatInputRef}
            userChatInputText={userChatInputText}
          />,
        ];
      })}
      {!editedMessage && userChatInputText && (
        <ChatMessage
          key="new-message"
          ownMessage={rightUserId === auth.userId}
          messageSnapshot={{
            data: () => ({ formattedText: userChatInputText }),
          }}
          isRead={false}
          isEdited={true}
          setEditedMessage={setEditedMessage}
          showAvatar={false}
          showAgentName={false}
          userChatInputRef={userChatInputRef}
          userChatInputText={userChatInputText}
        />
      )}
    </div>
  );
};
export default ChatList;
